
.branch-card 
{
  background-color: #f2f2f2 !important;
  width: 200px !important;
}
.entity-card
{
  background-color: #f2f2f2 !important;
}

.main-content
{
  width: calc(100% - 150px);
}

.ui.attached.header, .LoginHeader
{
  background-color:#f2f2f2 !important;
}



.ui.menu.leftNav
{
  background-color: #767676 !important;
}
.ui.menu.topNavBar
{
  background-color:#f2f2f2 !important;
}


.interestRateFilter .DateRangePickerInput 
{
  
  border-color: rgba(34, 36, 38, 0.15) !important;
  border-radius: 4px  !important;
  border-style: solid !important;
  border-width: 1px  !important;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px inset  !important;
  box-sizing:  border-box  !important;
  color:  rgba(0, 0, 0, 0.87) !important;
  outline-color: rgba(0, 0, 0, 0.87) !important;
}
.interestRateFilter .DateRangePickerInput_arrow_svg
{
  fill: rgba(191,191,191,.87) !important;
}
.interestRateFilter .DateInput 
{
  width: 40%;
}
.interestRateFilter .DateInput input
{
  font-size: 14px !important;
  border: 0 !important;
  width: 100%;
  padding-bottom: 10px !important;
  padding-left: 4px !important;
  padding-right:4px !important;
  padding-top:10px !important;
  line-height:16px !important;
}

.interestRateFilter .DateInput input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(191,191,191,.87);
}
.interestRateFilter .DateInput input::-moz-placeholder { /* Firefox 19+ */
  color: rgba(191,191,191,.87);
}
.interestRateFilter .DateInput input:-ms-input-placeholder { /* IE 10+ */
  color: rgba(191,191,191,.87);
}
.interestRateFilter .DateInput input:-moz-placeholder { /* Firefox 18- */
  color: rgba(191,191,191,.87);
}



.InlineSingleDatePicker .SingleDatePickerInput
{
  border: 0 !important;
}
.InlineSingleDatePicker .SingleDatePicker input 
{
  
  border-color: rgba(34, 36, 38, 0.15);
  border-radius: 4px  !important;
  border-style: solid !important;
  border-width: 1px  !important;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px inset  !important;
  box-sizing:  border-box  !important;
  color:  rgba(0, 0, 0, 0.87) !important;
  outline-color: rgba(0, 0, 0, 0.87) !important;

  font-size: 14px !important;
  padding-bottom: 10px !important;
  padding-top:10px !important;
  line-height:16px !important;

}
.InlineSingleDatePicker .DateInput input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(191,191,191,.87);
}
.InlineSingleDatePicker .DateInput input::-moz-placeholder { /* Firefox 19+ */
  color: rgba(191,191,191,.87);
}
.InlineSingleDatePicker .DateInput input:-ms-input-placeholder { /* IE 10+ */
  color: rgba(191,191,191,.87);
}
.InlineSingleDatePicker .DateInput input:-moz-placeholder { /* Firefox 18- */
  color: rgba(191,191,191,.87);
}

.message.LoginError
{
    background-color:#fff6f6 !important;
}
.LoginImage
{
  border: 2px solid rgba(0,0,0,.1);
}
.message.positive.positiveNotification
{
  background-color:#e5f9e7 !important;
}
.message.negative.negativeNotification
{
  background-color: #ffe8e6 !important;
}
.message.warning.warningNotification
{
  background-color: #fffaf3 !important;
  display: block !important;
}

.transactionGrid .row
{
  padding-bottom: 2px !important;
}

.transactionForm .row
{
  padding-bottom: 2px !important;
}
.transactionForm label
{
  font-size: smaller;
  vertical-align: bottom;
}


.inline.field label {
  display: inline-block !important;
  width: 15% !important;
}

.inline.field.editInterestOverride label {
  width: 25% !important;
}

.pushable{
  margin-left:138px;
  overflow-y: hidden;
  padding-bottom: 2em !important;
}

#padlockMenu{
  height: 100%;
}

.padlockLogo{
  margin-bottom: 3.15em;
}

.topNavBar{
  margin-top: 0em !important;
}

.ui.visible.top.sidebar~.pusher {
  -webkit-transform: translate3d(0,47px,0);
  transform: translate3d(0,47px,0);
}


.scottland-divider {
	border-width: 2px;
  border-color: #91A509;
  border-style: solid;
  border-image: linear-gradient(to right,#91A509 ,#CDE240 );
  border-image-slice: 1;
  margin: 1em 0 1em 0;
}

.scottland-divider-small {
	border-width: 1px;
  border-color: #91A509;
  border-style: solid;
  border-image: linear-gradient(to right,#91A509 ,#CDE240 );
  border-image-slice: 1;
  margin: 0em 0 .5em 0;
}

.scottland-link {
  color: rgba(0,0,0,0.85) !important; 
}

.scottland-link:hover {
  color: rgba(0,0,0,0.85) !important; 
  text-decoration: underline !important;
}

.transaction-filter-row > * {
  margin-left: .5em !important;
}

.ui.pagination.menu .active.item {
	background-color: #b5cc18 !important;
	color: #fff;
}

.scottland-login-form {
  margin-top: 3em;
}

.scottland-login-form > .field,
.scottland-login-form > .button,
.scottland-login-form > .message {
  max-width: 35em !important;
}
.scottland-login-form > .message {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.scottland-transaction-form {
  padding-top: 2.5em;
}

.scottland-transaction-form .entity-selection-field > .field {
  margin:0 !important;
}

.scottland-transaction-form .header {
  margin-bottom: .33em !important;
} 

.scottland-transaction-form i {
  margin-right: .25em !important;
} 


.add-transaction-note-form-field {
  padding: 0 !important;
}

.add-transaction-note-form-field > .button {

  margin-left:.5em !important;
  margin-right: 0 !important;
}

.transaction-status-label > span:hover {
  text-decoration: underline;
}

.transaction-status-label:hover {
  cursor: pointer;
}

.transaction-read-only-view label {
  font-size: 1em;
  color: rgba(0,0,0,.87);
  font-weight: 700;
}

.rate-plan-percent-input {
    width: 50%!important;
    margin-left: auto!important;
    margin-right: auto!important;
}
.rate-plan-percent-input .ui.transparent.icon.input input {
  text-align:center!important;
  padding-right: 0!important;
}

.rate-plan-current-rates {
  float: right;
}

.rate-plan-current-rates span {
  padding-left:1em;
}

.rate-change-form  {
  margin-bottom:1em
}

.rate-change-form .label i {
  margin:0!important;
}

.rate-plan-segment {
  margin-bottom: 0!important;
  padding-bottom: 0!important;
}

.entity-edit-form .column .field {
  margin-bottom: 1.75em;
}

.entity-edit-form .column .field label {
  font-size: 16px!important;
  color: rgba(0,0,0,.87)!important;
  font-weight: 700!important;
}

.entity-edit-form .column .entity-form-balance-field label{
  width: unset!important;
}

.entity-edit-form .column .entity-form-balance-field input{
  float: right;
}

div.inline.field.entity-form-balance-field > div {
  width: 60%!important;
  float: right!important;
}

.member-edit-branch-dropdown i {
  float: right;
}